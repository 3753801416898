.projects-page-main-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 100px auto 30px;
    width: 98%;
}

.projects-page-main-title h2 {
    margin-bottom: 0px;

    color: black;
    font-size: 2.4rem;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

@media (max-width: 768px) {
    .projects-page-main-title {
        width: 90%;
    }

    .projects-page-main-title h2 {
        font-size: 2rem;
    }
}
