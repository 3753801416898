.contact-container {
    width: 98%;
    height: 77vh;
    margin: auto;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-msg {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-msg h3 {
    font-size: 9rem;
    font-family: "Six Caps", sans-serif;
    font-weight: 200;
    line-height: 1;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
}

.contact-form-container {
    width: 50%;
    height: 100%;
    /* background-image: url("https://rihanbucket.s3.amazonaws.com/bianco/092_CAVALLINO_V3_P.jpg"); */
    /* background-color: rgb(223, 223, 223); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .contact-container {
        height: unset;
        flex-direction: column;
    }

    .contact-msg {
        width: 90%;
        height: 100%;
        margin: auto;
    }
    .contact-msg h3 {
        font-size: 6.4rem;
        text-align: center;
    }
    .contact-form-container {
        width: 90%;
        height: unset;
        margin-top: 20px;
        padding: 20px 0;
    }
}
