.project-card-container {
    width: 24.62%;
    aspect-ratio: 1.4/1;
    position: relative;
    margin-top: 7px;
}

.project-card-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    transition: 0.2s ease-in;
    cursor: pointer;
}

.project-card-img:hover {
    border: solid 1px white;
}

.project-card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-title {
    width: 90%;
    margin: auto;
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.project-title h4 {
    color: white;
    font-size: 0.8rem;
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

@media (max-width: 768px) {
    .project-card-container {
        width: 100%;
        aspect-ratio: 1.4/1;
        margin-top: 20px;
    }
}
