.ai-page-main-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 100px auto 30px;
    width: 98%;
}

.ai-page-main-title h2 {
    font-size: 2.4rem;
    margin-bottom: 0px;
    color: black;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

.ai-cover-container {
    width: 98%;
    margin-top: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img2img {
    margin-bottom: 70px;
}
.gif {
    border-radius: 6px;
    width: 700px;
    height: 700px;
}

.gif img {
    padding: 50px;
    border-radius: 60px;
}

.ai-cover-container img {
    margin: auto;
    width: 100%;
}

.sketch-txt-cover {
    margin: auto;
    width: 98%;
    margin-bottom: 10px;

    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

.sketch-txt-cover .txt-cover-title {
    font-size: 1.5rem;
}
.sketch-txt-cover .txt-cover-desc {
    font-size: 1rem;
}

.ai-page-exp-title {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 30px auto 30px;
}

.ai-page-exp-title h3 {
    font-size: 2rem;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

.ai-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5%;
    width: 98%;
    margin: auto;
}

.gallery-ai-img {
    width: 24.62%;
    /* aspect-ratio: 1/1; */
    position: relative;
    margin-top: 7px;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .ai-page-main-title {
        width: 90%;
        margin: 70px auto 30px;
    }

    .ai-page-main-title h2 {
        font-size: 2rem;
    }

    .ai-cover-container {
        flex-direction: column;
        width: 90%;
    }

    .sketch-txt-cover .txt-cover-title {
        font-size: 1.2rem;
    }
    .sketch-txt-cover .txt-cover-desc {
        font-size: 0.9rem;
        font-weight: 400;
    }

    .sketch-txt-cover {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 90%;
        position: relative;
    }

    .ai-gallery {
        width: 98%;
    }
    .gallery-ai-img {
        width: 100%;
        aspect-ratio: 1.4/1;
        margin-top: 10px;
    }

    .gif {
        border-radius: 6px;
        width: 90%;
        width: unset;
        height: unset;
    }

    .gif img {
        padding: 0px;
        border-radius: 6px;
    }
    .ai-cover-container {
        width: 98%;
        margin-top: 0px;
    }

    .img2img {
        margin-bottom: 10px;
    }
}
