* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    /* background-color: rgb(16, 16, 16); */
    background-color: white;
}
