.about-container {
    margin: auto;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: top;
}

.about-page-main-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 100px auto 30px;
    width: 98%;
}

.about-page-main-title h2 {
    font-size: 2.4rem;
    margin-bottom: 0px;
    text-align: justify;
    color: black;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

.about-photo {
    width: 35%;
}

.about-photo img {
    width: 100%;
    border-radius: 10px;
}

.about-txt {
    width: 80%;
    margin: 0px 40px 0px 60px;
    font-size: 1.3rem;
    font-weight: 200;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    margin-bottom: 30px;
    text-align: justify;
}

.btn-contact {
    display: flex;

    align-items: center;
    width: 150px;
    margin-left: 0px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    cursor: pointer;
    transition: 0.1s ease-in;
}

.btn-contact:hover {
    background-color: rgb(190, 190, 190);
}

.about-txt p {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .about-page-main-title h2 {
        font-size: 2rem;
    }
    .about-page-main-title {
        width: 90%;
    }
    .about-container {
        width: 90%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .about-photo {
        width: 100%;
    }

    .about-photo img {
        width: 100%;
        border-radius: 10px;
    }

    .about-txt {
        width: 100%;
        margin: 30px 0px;

        font-size: 1.3rem;

        text-align: justify;
    }
}
