.project-img {
    display: block;
    margin: auto;
    width: 75vw;
    border-radius: 10px;
    margin-bottom: 20px;
}

.project-video {
    display: block;
    margin: auto;
    width: 75%;
    aspect-ratio: 1.8/1;
    border: 0;
}

@media (max-width: 768px) {
    .project-img {
        width: 90vw;
    }
    .project-video {
        width: 90%;
    }
}
