.cover-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.genspace-cover {
    width: 98%;
    height: 90%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 3%;
}

@media (max-width: 768px) {
    .cover {
        margin-top: 12%;
    }

    .cover-wrapper {
        height: 90vh;
    }
    .genspace-cover {
        content: url("https://rihanbucket.s3.us-east-1.amazonaws.com/genspace-ai/genspace-ai-phone.webp");
    }
}
